<template>
  <CreatePropertyStepperWrapper />
</template>

<script>
import CreatePropertyStepperWrapper from '@/components/property/CreatePropertyStepperWrapper'
export default {
  name: 'CardCreate',
  components: { CreatePropertyStepperWrapper }
}
</script>

<style scoped>

</style>
