<template>
  <PropertyCardStepper />
</template>

<script>
import PropertyCardStepper from '@/components/property/PropertyCardStepper'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'PropertyCardStepperWrapper',
  components: { PropertyCardStepper },
  created () {
    this.resetProperty()
    this.property.ownerId = this.user.id
  },
  computed: {
    ...mapGetters({
      user: 'userAuth/user',
      property: 'propertyCard/property'
    })
  },
  methods: {
    ...mapMutations({
      resetProperty: 'propertyCard/resetProperty'
    })
  }
}
</script>

<style scoped>

</style>
